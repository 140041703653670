function GetBreakpointWidth() {
    //var bp_Width = $(window).width();
    var bp_Width = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
    return bp_Width;
};
function GetBreakpointHeight() {
    //var bp_Height = $(window).height();
    var bp_Height = window.innerHeight > 0 ? window.innerHeight : window.screen.height;
    return bp_Height;
};

export { GetBreakpointHeight, GetBreakpointWidth }