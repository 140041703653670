// === babel/polyfill --- BEGIN --- ====
// --- before (with core-js@2)
//import "@babel/polyfill";
// --- after (with core-js@3)
import 'core-js/es/object';
import "core-js/stable";
import "regenerator-runtime/runtime";
// === babel/polyfill ---- END ---- ====

//import '../scss/styles-ea.scss';
import 'SrcJS/ha-loader.js';
import 'header';
import 'footer';
// FIXME !!! WebPerformance :: pictures :: Check what is better
// version 1
import 'Common/pictures.js';
// version 2
//import(/* webpackChunkName: "pictures-module" */ 'Common/pictures.js');
// -- end of WebPerformance :: pictures
import 'bootstrap/dist/js/bootstrap';
import { GetBreakpointWidth } from 'Utils/globals-bp';
import { UpdateSiteHeaderSticky } from 'Modules/headroom.js';

import 'numeral-with-locales';

global.$ = $;
global.jQuery = $;
global.jquery = $;

import 'jquery.easing';
import 'Vendors/jquery.ui.touch-punch';
//$.datepicker.regional[AppData.CurrentLocale.toLowerCase()];

moment.locale(AppData.CurrentLocaleCountry2);
//global.moment = moment;

numeral.locale(AppData.CurrentLocaleCountry2);
//global.numeral = numeral;

function totop_button(e) {
    var t = $("#back_to_top");
    t.removeClass("off on");
    if (e === "on") {
        t.addClass("on")
    } else {
        t.addClass("off")
    }
};

function backToTop() {
    $(document).on("click", "#back_to_top", function (e) {
        e.preventDefault();
        $("body,html").animate({
            scrollTop: 0
        }, $(window).scrollTop() / 3, "linear")
    })
};

function backButtonShowHide() {
    if (GetBreakpointWidth() < 768) {
        totop_button("off");
        return;
    }
    var e = $(window).scrollTop();
    var t = $(window).height();
    var n;
    if (e > 0) {
        n = e + t / 2
    } else {
        n = 1
    }
    if (n < 1e3) {
        totop_button("off")
    } else {
        totop_button("on")
    }
};

$(document).ready(function () {
    backToTop();
    $(window).scroll(function () {
        backButtonShowHide();
    });
    backButtonShowHide();
        setTimeout(function () {
            backButtonShowHide();
        }, 2000);
});

function InitScript_5_Headroom() {
    $(document).ready(UpdateSiteHeaderSticky);
    $(window).resize(UpdateSiteHeaderSticky);
};

// FIXME !!! WebPerformance :: InitScript_5_Headroom :: Check what is better
// version 1
//InitScript_5_Headroom();

// version 2
AppLoader.loaded(InitScript_5_Headroom);


////temp location / will be moved
//function InitScript_packagelogic() {
//
//    (function ($) {
//        $.fn.packageMouseOver = function () {
//            $('.package-day').removeClass('hover');
//            $(this).addClass('hover');
//        }
//    })(jQuery);
//
//};
//AppLoader.loaded(InitScript_packagelogic);