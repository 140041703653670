function showSection(section, force) {
    if (section && (section.is(':hidden') || force)) {
        section.stop().show();
        //section.stop().slideDown(300);
    }
};

function hideSection(section, force) {
    if (section && (section.is(':visible') || force)) {
        section.stop().hide();
        //section.stop().slideUp(300);
    }
};

export { showSection, hideSection }