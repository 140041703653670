import Headroom from 'headroom.js';
//import Headroom from 'headroom.js/dist/headroom';
//import 'headroom.js/dist/jQuery.headroom';
import { GetBreakpointWidth } from 'Utils/globals-bp';
/* *** Headroom *** */
global.siteHeadRoom = null;
function UpdateSiteHeaderSticky() {
    var elem = document.querySelector(".site-header, nav.navbar");
    if (global.siteHeadRoom == null) {
        var options = {
            "offset": 400,
            "tolerance": {
                "up": 5,
                "down": 0
            },
            "classes": {
                // when element is initialised
                "initial": "animated",
                // when scrolling up
                "pinned": "slideDown",
                // when scrolling down
                "unpinned": "slideUp",
                // when above offset
                "top": "headroom--top",
                // when below offset
                "notTop": "headroom--not-top",
                // when at bottom of scoll area
                "bottom": "headroom--bottom",
                // when not at bottom of scroll area
                "notBottom": "headroom--not-bottom"
            },
            onUnpin: function () {
                var mobileMenu = $('.site-header').find('.site-nav__mobile');
                if (mobileMenu.is(":visible")) {
                    mobileMenu.slideToggle(400);
                }

                // Dropdown menu ST version
                if (typeof siteHeadRoom_onUnpin == 'function')
                    siteHeadRoom_onUnpin();
            }
        };
        global.siteHeadRoom = new Headroom(elem, options);
    }
    var headroom = global.siteHeadRoom;

    //var maxWidth = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
    var maxWidth = GetBreakpointWidth();

    //if ($(window).width() >= 768 || maxWidth >= 768) {
    if (maxWidth >= 768) {
        if (headroom != null) {
            // to destroy
            //$(".site-header").headroom("destroy");
            headroom.destroy();
            var classes = headroom.classes;
            elem.classList.remove(
                classes.unpinned, classes.pinned,
                classes.top, classes.notTop,
                classes.initial,
                classes.bottom, classes.notBottom);
        }
    } else {
        if (headroom != null) {
            //$(".site-header").headroom(options);
            headroom.init();
        }
    }
};

export { UpdateSiteHeaderSticky }