import { GetBreakpointWidth, GetBreakpointHeight } from 'Utils/globals-bp';

export function GetIntersectionObserverConfig() {
    let maxWidth = GetBreakpointWidth();
    let maxHeight = GetBreakpointHeight();
    let rootMargin = Math.round(Math.min(maxWidth, maxHeight) / 2) + 'px';
    //console.log('>>> IntersectionObserver >> rootMargin: ', rootMargin);
    let config = {
        //rootMargin: '0px 0px 50px 0px',
        rootMargin: rootMargin,
        threshold: 0
    };
    return config;
}

export class LazyInitializer {
    constructor(element, cbInitializer, options) {
        this.options = Object.assign({},
            GetIntersectionObserverConfig(),
            options
        );
        this.element = element;
        this.cbInitializer = cbInitializer;

        this.bindEvents();
        //this.init();
    }

    bindEvents() {
        this._lazyInit = this._lazyInit.bind(this);
        //this.init = this.init.bind(this);
    }

    /**
     * Creates a Promise that is based on cbInitializer.
     * @returns A new Promise.
     */
    _lazyInit() {
        //console.log('>>> LazyInitializer >> _lazyInit ', this);
        let p;
        if (typeof this.cbInitializer == "function") {
            p = new Promise(async (resolve, reject) => {
                //console.log('>>> LazyInitializer >> _lazyInit >> before cbInitializer ', this);
                let r = await this.cbInitializer();
                //console.log('>>> LazyInitializer >> _lazyInit >> after cbInitializer ', r);
                resolve(r);
            })
        } else {
            p = Promise.resolve(this.cbInitializer);
        }
        return p;
    }

    /**
     * Creates a Promise that is resolved with result of initialization by cbInitializer.
     * Uses IntersectionObserver with options if it's possible.
     * @returns A new Promise.
     */
    init() {
        let promise;
        if (!!window.IntersectionObserver) {
            promise = new Promise((resolve, reject) => {
                let config = this.options;
                let _lazyInit = this._lazyInit;
                let observer = new IntersectionObserver(function (entries, self) {
                    entries.forEach(async entry => {
                        //console.log('>>> IntersectionObserver');
                        if (entry.isIntersecting) {
                            //console.log('>>> IntersectionObserver >> Initialize');
                            let r = await _lazyInit();
                            // Stop watching and initialize map
                            self.unobserve(entry.target);
                            // done
                            resolve(r);
                        }
                    });
                }, config);

                observer.observe(this.element);
            });
        } else {
            promise = this._lazyInit();
        }
        return promise;
    }
}
