import { isTouch } from 'Utils/device-utils';

import 'Vendors/jquery.inview';


function boxLoad(extra) {

	if (isTouch) {
		$('.hotel-slider__box').removeClass('hotel-slider__box--hover');
		$('.front-blog__box').removeClass('front-blog__box--hover');
	} else {
		$('.hotel-slider__box').addClass('hotel-slider__box--hover');
		$('.front-blog__box').addClass('front-blog__box--hover');
	}

	$('.front-blog__box').each(function () {
		$(this).find('.front-blog__caption').show();
		temp = $(this).find('h2');
        if (temp.height() < 30) 
            temp.addClass("padding");
        else
            temp.removeClass("padding");
		$(this).find('.front-blog__caption').css('bottom', $(this).find('.front-blog__caption').height() * -1 + 42);
	});

	$('.front-blog__box').one('inview', function () {
			$(this).find('.front-blog__caption').show();
			temp = $(this).find('h2');
            if (temp.height() < 30)
                temp.addClass("padding");
            else
                temp.removeClass("padding");
			$(this).find('.front-blog__caption').css('bottom', $(this).find('.front-blog__caption').height() * -1 + 42);
		}
	);

    boxLoad_GalleryMosaic(0);

    if (extra) setTimeout(boxLoad, 500, 0);

	//if (extra) setTimeout("boxLoad(0)",200);
};

function boxLoad_GalleryMosaic(extra) {

	if (isTouch) {
		$('.gallery-mosaic__box').removeClass('gallery-mosaic__box--hover');
	} else {
		$('.gallery-mosaic__box').addClass('gallery-mosaic__box--hover');
	}

	$('.gallery-mosaic__box').each(function () {
		$(this).find('.gallery-mosaic__caption').show();
		temp = $(this).find('h2,h3,h4');
        if (temp.height() < 30) 
            temp.addClass("padding");
        else
            temp.removeClass("padding");
        $(this).find('.gallery-mosaic__caption').css('bottom', $(this).find('.gallery-mosaic__caption').height() * -1 + 42);

        $(this).css('height', $(this).width() * 0.8);
	});

	$('.gallery-mosaic__box').one('inview', function () {
			$(this).find('.gallery-mosaic__caption').show();
			temp = $(this).find('h2,h3,h4');
            if (temp.height() < 30)
                temp.addClass("padding");
            else
                temp.removeClass("padding");
			$(this).find('.gallery-mosaic__caption').css('bottom', $(this).find('.gallery-mosaic__caption').height() * -1 + 42);
		}
	);

	if (extra) setTimeout("boxLoad_GalleryMosaic(0)", 500);
};


export { boxLoad }