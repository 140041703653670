import * as hs from 'Common/headersearch';

// FIXME !!! WebPerformance :: InitScript_HeaderSearch :: Check what is better
// version 1
//$(document).ready(function () {
//	hs.InitScript_HeaderSearch();
//});

// version 2
AppLoader.loaded(hs.InitScript_HeaderSearch);

// top menu expand destination list
window.topmenuShowAllDestinations = function (countryPageId) {
	$(".destination-" + countryPageId + ".destination-hidden").removeClass("destination-hidden");
	$(".destination-" + countryPageId + ".see-more").remove();
};