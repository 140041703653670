/* 
 * 2021-06-14: Refactoring
 * Extracted from ha-searchlogic-functions.js
*/
import { updateUI } from 'SrcJS/script--updateUI';

export function matchHeightsRejsmal() {
    updateUI();

    //if ($(window).width() > 767 && $('.country-pic-info').length > 0) {
    //    $('.country-pic-info').matchHeight({
    //        byRow: true,
    //        property: 'height',
    //        //property: 'min-height',
    //        //target: null,
    //        //remove: false
    //    });
    //};
};
