import { boxLoad } from 'SrcJS/script--boxLoad';
import { updateUI } from 'SrcJS/script--updateUI';
import 'readmore-js';
import { matchHeightsRejsmal } from 'Utils/ha-searchlogic-functions--matchHeightsRejsmal';

/***************************** Mere *****************************/

$(document).on('click', '.mere', function (event) {
    event.preventDefault();
    let $this = $(this);
    $this.prev(".meretekst").slideToggle("fast", function () {
        // FIX Bug: Jumping <span> html-tag after animation
        // in 'animation' state the $moretext has style 'display: inline-block'
        // after complition the $moretext has style 'display: inline'
        let $moretext = $(this);
        if ($moretext.is('span') && $moretext.css('display') === 'inline' && $moretext.is(':visible')) {
            $moretext.css('display','inline-block');
        }
        updateUI();
    });
    let text = $this.text();
    let button = '';
    if (text == $this.data('less')) {
        if ($this.hasClass("withFadeOut")) {
            $this.parent().find("#fadeout").css("display", "block");
        }
        button = $this.data('more');
        $this.removeClass('expanded');
    }
    else {
        if ($this.hasClass("withFadeOut")) {
            $this.parent().find("#fadeout").css("display", "none");
        }
        button = $this.data('less');
        $this.addClass('expanded');
    }
    /*
    switch (text) {
        case "Læs mere":
            button = "Vis mindre tekst";
            break;
        case "Vis mindre tekst":
            button = "Læs mere";
            break;
    }*/
    $this.text(button);
});

$(document).on('click', '.readmore-dots--btn', function (event) {
    event.preventDefault();
    let $this = $(this);
    //$this.prev(".readmore-dots--text").toggleClass("shown");
    let text = $this.text();
    let button = '';
    if (text == $this.data('less')) {
        button = $this.data('more');
        $this.removeClass('expanded');
        $this.parent().find('.dots').css('display', '');
        $this.prev(".readmore-dots--text").removeClass("shown");
    } else {
        button = $this.data('less');
        $this.addClass('expanded');
        $this.parent().find('.dots').css('display', 'none');
        $this.prev(".readmore-dots--text").addClass("shown");
    }
    $this.text(button);
    updateUI();
});

$(document).on('click', '.mereDestinations', function (event) {
    event.preventDefault();
    $(this).prev(".meretekst").slideToggle("fast", matchHeightsRejsmal);
    var tekst = $(this).text();
    let button = '';
    if ($(this).text() == $(this).data('less')) {
        button = $(this).data('more');
        $(this).removeClass('show');
    }
    else {
        button = $(this).data('less');
        $(this).addClass('show');
    }
    /*
    switch (tekst) {
        case "Læs mere":
            button = "Vis mindre tekst";
            break;
        case "Vis mindre tekst":
            button = "Læs mere";
            break;
    }*/
    $(this).text(button);
});

$("#mere2").click(function (event) {
    event.preventDefault();
    $("#meretekst2").slideToggle("fast", updateUI);
    var tekst = $(this).text();
    let button = '';
    switch (tekst) {
        case "Vis flere":
            button = "Vis færre";
            break;
        case "Vis færre":
            button = "Vis flere";
            break;
    }
    $(this).text(button);
});

$("#mere3").click(function (event) {
    event.preventDefault();
    $("#meretekst3").slideToggle("fast", updateUI);
    var tekst = $(this).find("strong").text();
    let button = '';
    switch (tekst) {
        case "flere":
            button = "færre";
            break;
        case "færre":
            button = "flere";
            break;
    }
    $(this).find("strong").text(button);
});

$(document).on('click', '.show-more-items', function (event) {
    event.preventDefault();
    var $btn = $(this);
    //var holder = $(this).prev("ul.more-items");
    var holder = $(this).closest(":has(ul.more-items)").find("ul.more-items");
    var limits = parseInt(holder.data('limits')) - 1;
    //holder.children('li:gt(' + limits + ')').slideToggle("fast");
    var $items = holder.children('li:gt(' + limits + ')');
    if ($items.length > 0) {
        var indxAnimated = 0;
        var flgExtra = !$btn.hasClass('mere'); // there is extra 'work' in some places, like FrontBlogMasonry
        $items.slideToggle("fast", function () {
            let $this = $(this);
            requestAnimationFrame(function () {
                if ($this.is(":visible")) {
                    $items.removeClass('hidden');
                    $items.css("display", "block");
                } else {
                    $items.addClass('hidden');
                }
            });
            if (flgExtra) {
                indxAnimated++;
                if (indxAnimated == $items.length) {
                    var text = $btn.text();
                    var $lastItem = $(this);
                    var flgExpanded = $lastItem.is(":visible");
                    if (flgExpanded && text != $btn.data('less')) {
                        text = $btn.data('less');
                    } else {
                        text = $btn.data('more');
                    }
                    if (text != $btn.text()) {
                        $btn.text(text);
                        if (text == $btn.data('less')) {
                            $btn.addClass('expanded');
                        } else {
                            $btn.removeClass('expanded');
                        }
                    }

                    requestAnimationFrame(function () {
                        $btn.blur();
                        window.slimmage.checkResponsiveImages();
                        //boxLoad(0);
                        updateUI();
                        holder.trigger('more-items.show-more-items', [{ expanded: flgExpanded }]);
                    });
                }
            }
        });
    }
    //holder.children('li:gt(' + limits + ')').slideToggle("fast", function () {
    //    if ($(this).is(":hidden")) {
    //        $(this).addClass('hidden');
    //    } else {
    //        $(this).removeClass('hidden');
    //    }
    //});
    requestAnimationFrame(function () {
        window.slimmage.checkResponsiveImages();
        boxLoad(0);
        //updateUI();
    });
    //Text changed by selector '.mere'
    //var tekst = $(this).text();
    //if ($(this).text() == $(this).data('less'))
    //    button = $(this).data('more');
    //else
    //    button = $(this).data('less');
    //$(this).text(button);
});
