//import 'jquery-ui';
import 'jquery-ui/ui/widgets/autocomplete';
import { GetBreakpointWidth } from 'Utils/globals-bp';

/*** headersearch *** searchautocomplete ***/
function split(val) {
    return val.split(/,\s*/)
};

function extractLast(term) {
    return split(term).pop()
};

function initSearchAutocomplete() {
    var mobile_update_nav_menu = function (sender, event, method) {
        var $menu = $(sender).closest('.topmenu__mobile').find('.site-nav__mobile-click');
        if (method == 'hide') {
            $menu.addClass("hide-bp2-down");
            $('.site-header').find('.site-nav__mobile').slideUp(400, function () {
                // clear zIndex after hide
                var $siteHeader = $('.site-header--fixed');
                $siteHeader.css('z-index', '');
            });
        } else {
            $menu.removeClass("hide-bp2-down");
        }
    };

    var noresults_msg = $("#headersearch").attr("data-noresult");
    var mobile_get_classes = function ($searchautocomplete) {
        if (!$searchautocomplete.length || !$searchautocomplete.hasClass("searchautocomplete-mobile"))
            return "";

        var $parent = $searchautocomplete.closest('.site-header--fixed');
        if (!$parent.length)
            return "";

        if ($parent.css('position') == 'fixed') {
            return "is-fixed-parent";
        } else {
            return "";
        }
    }

    var mobile_update_searchautocomplete_menu = function ($searchautocomplete) {
        if (!$searchautocomplete) {
            $searchautocomplete = $(".searchautocomplete-mobile");
            //console.log('$searchautocomplete is undefined.');
        } else {
            if (!$searchautocomplete.hasClass("searchautocomplete-mobile")) {
                //console.log('nofound searchautocomplete-mobile');
                return;
            }
        }

        $searchautocomplete.each(function (i, elem) {
            var $elem = $(elem);
            var $widget = $elem.autocomplete("widget");
            var cls = mobile_get_classes($elem);

            if (GetBreakpointWidth() > 768) {
                $widget.removeClass(cls);
                //console.log('searchautocomplete-menu: width > 768');
            } else {
                if (!$widget.hasClass(cls)) {
                    $widget.addClass(cls);
                    //console.log('searchautocomplete-menu: width <= 768. added class.');
                } else {
                    //console.log('searchautocomplete-menu: width <= 768. has class.');
                }
            }
        });
    };

    var $searchautocomplete = $(".searchautocomplete, .searchautocomplete-mobile");
    $searchautocomplete.each(function (i, elem) {
        var $elem = $(elem);

        $elem.autocomplete({
            position: { my: "right top", at: "right bottom", collision: "flipfit none" },
            classes: {
                "ui-autocomplete": mobile_get_classes($elem)
            },
            source: function (request, add) {
                var js = {
                    Term: extractLast(request.term)
                };
                var jsondata = JSON.stringify(js);

                $.ajax({
                    url: "/api/searchpages",
                    type: "POST",
                    data: jsondata,
                    contentType: 'application/json',
                    headers: { 'Accept': 'application/json' },
                    success: function (data) {
                        var rows = new Array;
                        if (data && data.Pages && data.Pages.length > 0) {
                            for (var i = 0; i < data.Pages.length; i++)
                                rows[i] = {
                                    data: "",
                                    result: data.Pages[i].Url,
                                    value: data.Pages[i].Name,
                                    type: "link"
                                };
                        } else {
                            var row = [noresults_msg, request.term];
                            rows[0] = {
                                data: row,
                                value: row[0],
                                result: row[1],
                                type: "text"
                            };
                        }
                        add(rows)
                    }
                })
            },
            search: function () {
                var term = extractLast(this.value);
                return !(term.length < 3) && void $(this).addClass("working")
            },
            open: function () {
                $(this).removeClass("working");
                $('.searchautocomplete-menu .ui-menu-item a').removeClass('ui-corner-all');
                $(".searchautocomplete-menu").css({ /*top: "+=3",*/ left: "+=3" });
                mobile_update_searchautocomplete_menu($(this));
            },
            select: function (e, ui) {
                $("*").addClass("loading");
                // 2018-02-26 Disabled query params
                //var query = "";
                //if (searchViewModel != null && $('#search-view-model').length > 0) {
                //    query = searchViewModel.GetCurrentQuery(true, true) + "#search-results";
                //} else if (myTripSearchViewModel != null && $('#mytrip').length > 0) {
                //    query = myTripSearchViewModel.GetCurrentSearchQuery() + "#search-results";
                //}
                //window.location.href = ui.item.result + query;
                window.location.href = ui.item.result;
            },
            renderMenu: function (ul, items) {
                var classes = "";
                if (this['classes']) {
                    classes = this['classes']["ui-autocomplete"];
                }
                $(ul).addClass(classes).addClass('searchautocomplete-menu').removeClass('ui-corner-all');
            },
            renderItem: function (ul, item) {
                if (item.type == "link") {
                    return $('<li>')
                        .append($('<a>').html(item.value))
                        .appendTo(ul);
                } else if (item.type == "text") {
                    return $('<li>')
                        .html(item.value)
                        .appendTo(ul);
                }
            },
        }).focus(function (event) {
            $(this).addClass("focus");
            var $wrapper = $(this).parents('.searchautocomplete-wrapper');
            $wrapper.addClass("focus");
            $wrapper.addClass("expanded");
            // hide mybooking
            var $mybooking = $(this).parents('.mini-topmenu').find('.mybooking-wrapper');
            $mybooking.addClass("hide-bp2-max");

            // hide menu button
            mobile_update_nav_menu(this, event, 'hide');

            //console.log("focus");
        }).focusout(function (event) {
            $(this).removeClass("focus");
            $(this).css('display', '');
            var $wrapper = $(this).parents('.searchautocomplete-wrapper');
            var value = $(this).val();
            $wrapper.removeClass("focus");
            if (!value || value == "") {
                $wrapper.removeClass("expanded");
                var $mybooking = $(this).parents('.mini-topmenu').find('.mybooking-wrapper');
                $mybooking.removeClass("hide-bp2-max");

                // show menu button
                mobile_update_nav_menu(this, event, 'show');
            }
        });

    });

    $(".searchautocomplete-wrapper label").on("click", function (e) {
        //e.preventDefault();
        mobile_update_nav_menu(this, e, 'hide');

        var $wrapper = $(this).closest(".searchautocomplete-wrapper");
        $wrapper.addClass("expanded");
        $wrapper.addClass("focus");
        //var $searchautocomplete = $wrapper.find('.searchautocomplete');
        //if (!$searchautocomplete.is(':focus')) {
        //    $searchautocomplete.focus();
        //}
    });

    $(window).resize(function () {
        mobile_update_searchautocomplete_menu();
    });
};

function InitScript_HeaderSearch() {
    //$(document).ready(function () {
    //    initSearchAutocomplete();
    //});
    $.widget("ui.autocomplete", $.ui.autocomplete, {

        options: {
            renderItem: null,
            renderMenu: null
        },

        _renderItem: function (ul, item) {
            if ($.isFunction(this.options.renderItem))
                return this.options.renderItem(ul, item);
            else
                return this._super(ul, item);
        },

        _renderMenu: function (ul, items) {
            this._super(ul, items);

            if ($.isFunction(this.options.renderMenu)) {

                this.options.renderMenu(ul, items);

            }
        },

    });

    initSearchAutocomplete();
};

export { InitScript_HeaderSearch }