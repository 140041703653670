import 'Vendors/jquery.cookie';

/***************************************************
 * From ha-searchlogic.js
 **************************************************/

export function getParameterByName(name) {
    name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(window.location.search);
    return results == null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
};

export function updateQueryStringWithoutHistory() {
    if (window.history.replaceState) {
        var withoutPage = !searchViewModel.IsSearch();
        let withRTBoards = !!searchViewModel.IsHotel();
        var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + searchViewModel.GetCurrentQuery(false, withoutPage, withRTBoards) + "#search-results";
        //prevents browser from storing history with each change:
        window.history.replaceState({ path: newurl }, '', newurl);
    }
};

export function updateQueryStringWithHistory() {
    //window.location.search = searchViewModel.GetCurrentQuery();// + "#search-results";
    if (history.pushState) {
        var withoutPage = !searchViewModel.IsSearch();
        let withRTBoards = !!searchViewModel.IsHotel();
        var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + searchViewModel.GetCurrentQuery(false, withoutPage, withRTBoards) + "#search-results";
        window.history.pushState({ path: newurl }, '', newurl);
    }
};

export function setCookieDepartureAirportCode(departureAirportCode, airportOnly) {
    var name = "searchViewModel_DepartureAirportCode";
    if(airportOnly) {
        name += "_airportOnly";
    }
    var options = { expires: 14, path: '/' };
    if (typeof (departureAirportCode) != 'undefined') {
        var value = departureAirportCode;
        $.cookie(name, value, options);
    } else {
        $.removeCookie(name, options);
    }
};

export function getCookieDepartureAirportCode(airportOnly) {
    var name = "searchViewModel_DepartureAirportCode";
    if(airportOnly) {
        name += "_airportOnly";
    }
    var value = $.cookie(name);
    var departureAirportCode = '';
    if (typeof(value) != 'undefined' && value !== null) {
        departureAirportCode = value;
    }
    return departureAirportCode;
};


export function setCookieItemListId(item_list_id) {
    var name = "dl_item_list_id";
    var options = { expires: 14, path: '/' };
    if (typeof (item_list_id) != 'undefined') {
        var value = item_list_id;
        $.cookie(name, value, options);
    } else {
        $.removeCookie(name, options);
    }
};

export function getCookieItemListId() {
    var name = "dl_item_list_id";
    var value = $.cookie(name);
    var item_list_id = '';
    if (typeof(value) != 'undefined' && value !== null) {
        item_list_id = value;
    }
    return item_list_id;
};

export function setCookieItemListName(item_list_name) {
    var name = "dl_item_list_name";
    var options = { expires: 14, path: '/' };
    if (typeof (item_list_name) != 'undefined') {
        var value = item_list_name;
        $.cookie(name, value, options);
    } else {
        $.removeCookie(name, options);
    }
};

export function getCookieItemListName() {
    var name = "dl_item_list_name";
    var value = $.cookie(name);
    var item_list_name = '';
    if (typeof(value) != 'undefined' && value !== null) {
        item_list_name = value;
    }
    return item_list_name;
};

export function setCookieDate(name, dt) {
    var options = { expires: 14, path: '/' };
    if (dt) {
        var value = moment(dt).format("YYYY-MM-DD");
        $.cookie(name, value, options);
    } else {
        $.removeCookie(name, options);
    }
};

export function getCookieDate(name) {
    var value = $.cookie(name);
    var dt = null;
    if (value) {
        dt = moment(value, "YYYY-MM-DD").toDate();
    }
    return dt;
};

export function setCookieRooms(rooms) {
    var name = "searchViewModel_Rooms";
    var options = { expires: 14, path: '/' };
    if (typeof (rooms) != 'undefined' && rooms != null && rooms.length > 0) {
        var value = JSON.stringify(rooms);
        $.cookie(name, value, options);
    } else {
        $.removeCookie(name, options);
    }
};

export function getCookieRooms() {
    var name = "searchViewModel_Rooms";
    var value = $.cookie(name);
    var rooms = null;
    if (typeof(value) != 'undefined') {
        rooms = JSON.parse(value);
    }
    return rooms;
};

// 2018-04-27 Unused now
//var arrayUnique = function (a) {
//    return a.reduce(function (p, c) {
//        if (p.indexOf(c) < 0) p.push(c);
//        return p;
//    }, []);
//};

export function runGoogleAdwords(searchViewModel, force) {
    var flgRun = force == true;
    if (searchViewModel != null) {
        // For HotelPage run with 'hrental_totalvalue' after SearchResult 
        if (searchViewModel.IsHotel() && searchViewModel.SingleHotelResult()
            && !searchViewModel.SearchInProgress() && searchViewModel.RetrievingDataReady()) {
            flgRun = true;
        }
        // For CountryPage and DestinationPage run before searching
        // FIXME !!! SearchViewModel refactoring :: searchViewModel.SearchInProgress
        if (!searchViewModel.IsHotel() && AppData.CurrentThemeId == null
             && (typeof searchViewModel.SearchInProgress != "undefined" && searchViewModel.SearchInProgress()) 
             && !searchViewModel.RetrievingDataReady()) {
            flgRun = true;
        }
    }

    if (flgRun && (typeof run_google_remarketing == 'function')) {
        // Google AdWords
        run_google_remarketing();
    }
};

export function updateTrivagoTracking() {
    var hotelId = AppData.CurrentHotelId;

    var isTrivago = false;
    if (document.getElementById("search-view-model") && hotelId != null) {
        var meta = getParameterByName("meta");
        if (meta != "" && meta.length > 0) {
            var metaItems = meta.split(",");
            isTrivago |= metaItems.indexOf("ttfc") >= 0;
        }
    }

    if (document.getElementById("mytrip")) {
        var ttfc = getParameterByName("ttfc");
        isTrivago |= ttfc != null && ttfc != "" && ttfc.toLowerCase() == 'true';
    }

    if (isTrivago) {
        var options = { expires: 30, path: '/' };
        var roomcount = parseInt(getParameterByName("rooms"));
        var rtbAllowed = false;
        var preSelectedRooms = [];
        for (var i = 0; i < roomcount; i++) {            
            var roomTypeWithBoardAndCancelPolicy = [];
            rtbAllowed = rtbAllowed || (getParameterByName("r" + i + "tb") != null && getParameterByName("r" + i + "tb") != "");
            var paramRTB = getParameterByName("r" + i + "tb");
            var roomType = -1;
            var roomBoard = "";
            var refundableStr = "";
            var refundable = false;
            if (paramRTB != "") {
                roomTypeWithBoardAndCancelPolicy = paramRTB.split("~");
                if (roomTypeWithBoardAndCancelPolicy.length > 0 && roomTypeWithBoardAndCancelPolicy[0] != "") {
                    roomType = parseInt(roomTypeWithBoardAndCancelPolicy[0]);
                }
                if (roomTypeWithBoardAndCancelPolicy.length > 1 && roomTypeWithBoardAndCancelPolicy[1] != "") {
                    roomBoard = roomTypeWithBoardAndCancelPolicy[1];
                }
                if (roomTypeWithBoardAndCancelPolicy.length > 2 && roomTypeWithBoardAndCancelPolicy[2] != "") {
                    refundableStr = roomTypeWithBoardAndCancelPolicy[2];
                    refundable = refundableStr == "R";
                }
            }

            var room = { "rt": roomType, "rb": roomBoard, "ref": refundable };
            preSelectedRooms.push(room);
        }
        var value = {
            flag: true,
            hotelId: hotelId,
            preSelectedRooms: preSelectedRooms
            //fillup preselected items
        };
        $.cookie("ttfc", JSON.stringify(value), options);
    }
};

export function checkTrivagoTracking() {
    var value = JSON.parse($.cookie("ttfc"));
    return value != null;
};

export function getTrivagoTrackingValue() {
    var value = JSON.parse($.cookie("ttfc"));
};

export function getAffiliateAttribution() {
	var value = $.cookie("aff_attribution");
	if (!value)
		return '';
	return value;
};
export function getAffiliatePath() {
	var value = $.cookie("aff_path");
	if (!value)
		return '';
	return value;
};

export function getAffiliateReference() {
	var value = $.cookie("aff_reference");
	if (!value)
		return '';
	return value;
};



/***************************************************
 * From ha-mytriplogic.js
 **************************************************/

export function consoleLog(msg) {
    console.log(msg);
};

export function round5(x) {
    return Math.ceil(x / 5) * 5;
};

String.prototype.replaceAll = function(search, replacement) {
    var target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
};

String.format = function () {
    // The string containing the format items (e.g. "{0}")
    // will and always has to be the first argument.
    var theString = arguments[0];

    // start with the second argument (i = 1)
    for (var i = 1; i < arguments.length; i++) {
        // "gm" = RegEx options for Global search (more than one instance)
        // and for Multiline search
        var regEx = new RegExp("\\{" + (i - 1) + "\\}", "gm");
        theString = theString.replace(regEx, arguments[i]);
    }

    return theString;
};

//Capitalize the First Letter
String.prototype.capitalize = function() {
    var target = this;
    return target[0].toUpperCase() + target.slice(1);
};
//Capitalize the First Letter of Each Word
String.prototype.capitalizeFirstLetterOfEachWord = function() {
    var target = this;
    return target.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
};


$.sum = function (arr) {
    var r = 0;
    $.each(arr, function (i, v) {
        r += v;
    });
    return r;
};

/** JavaScript object deep comparison
 * based on source: https://gist.github.com/nicbell/6081098
 */
Object.compare = function (obj1, obj2) {
    // Check if 'objects' are of same type
    if (typeof (obj1) !== typeof (obj2)) return false;

	//Loop through properties in object 1
	for (var p in obj1) {
		//Check property exists on both objects
		if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p))
			return false;

		if (obj1[p] === null && obj2[p] !== null)
			return false;
		if (obj2[p] === null && obj1[p] !== null)
			return false;

		switch (typeof(obj1[p])) {
			//Deep compare objects
		case 'object':
			if (!Object.compare(obj1[p], obj2[p]))
				return false;
			break;
			//Compare function code
		case 'function':
			if (typeof(obj2[p]) == 'undefined' || (p != 'compare' && obj1[p].toString() != obj2[p].toString()))
				return false;
			break;
			//Compare values
		default:
			if (obj1[p] === '' && obj2[p] !== '')
				return false;
			if (obj2[p] === '' && obj1[p] !== '')
				return false;
			if (obj1[p] != obj2[p])
				return false;
		}
	}

	//Check object 2 for any extra properties
	for (var p in obj2) {
		if (typeof(obj1[p]) == 'undefined')
			return false;
	}
	return true;
};

/** Case-insensitive getter 
 * @param {any} obj
 * @param {any} prop
 */
Object.getter = function (obj, prop) {
    let val = undefined;
    if (!obj)
        return val;

    prop = (prop + "").toLowerCase();

    for (var p in obj) {
        if (prop !== (p + "").toLowerCase())
            continue;

        //if (obj.hasOwnProperty(p)) {
        //    val = obj[p];
        //    break;
        //}
        if (typeof ko !== "undefined") {
            if (ko.isObservable(obj[p])) {
                val = ko.unwrap(obj[p]);
                break;
            }
        }
        let flgSwitch = true;
        switch (typeof obj[p]) {
            case 'function':
                val = obj[p].apply(obj, []);
                break;
            case 'string':
                val = obj[p];
                break;
            default:
                flgSwitch = false;
                break;
        }
        if (flgSwitch)
            break;
    }

    return val;
};

export function deepClone(obj) {
    //return JSON.parse(JSON.stringify(obj));
    //return Object.assign({}, obj);
    var clone = {};
    for (var i in obj) {
        if (obj[i] != null && typeof (obj[i]) == "object")
            clone[i] = deepClone(obj[i]);
        else
            clone[i] = obj[i];
    }
    return clone;
};

export function getSearchParameters(uri) {
    if (!uri || uri.length == 0 || uri == '')
        return null;

    var queryString = uri;
    var qPos = uri.indexOf('?');
    if (qPos > 0) {
        queryString = queryString.substring(qPos);
    }

    var tagPos = queryString.indexOf('#');
    if (tagPos >= 0) {
        queryString = queryString.substring(0, tagPos);
    }

    var params = {};
    queryString.replace(
        new RegExp("([^?=&]+)(=([^&#]*))?", "g"),
        function ($0, $1, $2, $3) { params[$1] = $3; }
    );
    return params;
};
export function getSearchQueryString(searchParams) {
    if (!searchParams)
        return "";
    var query = [];
    for (let key in searchParams) {
        query.push(key + '=' + searchParams[key]);
    }
    return query.join('&');
};

export function modifyQueryString(url, queryStringModification, anchor) {
    if (url === null) url = '';
    //url = url.toLowerCase();

    if (queryStringModification === null) queryStringModification = '';
    //queryStringModification = queryStringModification.toLowerCase();

    if (anchor === null) anchor = '';
    //anchor = anchor.toLowerCase();

    let str = '';
    let str2 = '';
    if (url.includes('#')) {
        str2 = url.substring(url.indexOf('#') + 1);
        url = url.substring(0, url.indexOf('#'));
    }
    if (url.includes('?')) {
        str = url.substring(url.indexOf('?') + 1);
        url = url.substring(0, url.indexOf('?'));
    }
    if (queryStringModification !== '') {
        if (str !== '') {
            let dictionary = {};
            str.split('&').forEach(str3 => {
                if (str3 !== '') {
                    let strArray = str3.split('=');
                    if (strArray.length === 2) {
                        dictionary[strArray[0]] = strArray[1];
                    } else {
                        dictionary[str3] = null;
                    }
                }
            });
            queryStringModification.split('&').forEach(str4 => {
                if (str4 !== '') {
                    let strArray2 = str4.split('=');
                    // if (strArray2.length === 2) {
                    //     dictionary[strArray2[0]] = strArray2[1];
                    // } else {
                    //     dictionary[str4] = null;
                    // }
                    let newValue = (strArray2.length === 2) ? strArray2[1] : null;
                    let newKey = (strArray2.length === 2 && strArray2[0] && strArray2[0].trim() !== '') ? strArray2[0] : str4;
                    let oldValue = null;
                    let oldKey = Object.keys(dictionary).find(k => { return k.toLowerCase() === newKey.toLowerCase(); });
                    if (oldKey && oldKey.trim() !== '') {
                        oldValue = dictionary[oldKey];
                        if (oldKey !== newKey) {
                            delete dictionary[oldKey];
                        }
                    }
                    dictionary[newKey] = newValue;
                }
            });            
            let builder = [];
            for (let key in dictionary) {
                if (dictionary.hasOwnProperty(key)) {
                    if (builder.length > 0) {
                        builder.push('&');
                    }
                    builder.push(key);
                    if (dictionary[key] !== null) {
                        builder.push('=');
                        builder.push(dictionary[key]);
                    }
                }
            }
            str = builder.join('');
        } else {
            str = queryStringModification;
        }
    }
    if (anchor !== '') {
        str2 = anchor;
    }
    //return (url + (str === '' ? '' : ('?' + str)) + (str2 === '' ? '' : ('#' + str2))).toLowerCase();
    return url + (str === '' ? '' : ('?' + str)) + (str2 === '' ? '' : ('#' + str2));
}

if (!Array.prototype.findIndex) {
    Array.prototype.findIndex = function (predicate) {
        if (this == null) {
            throw new TypeError('Array.prototype.findIndex called on null or undefined');
        }
        if (typeof predicate !== 'function') {
            throw new TypeError('predicate must be a function');
        }
        var list = Object(this);
        var length = list.length >>> 0;
        var thisArg = arguments[1];
        var value;

        for (var i = 0; i < length; i++) {
            value = list[i];
            if (predicate.call(thisArg, value, i, list)) {
                return i;
            }
        }
        return -1;
    };
};

if (!Array.prototype.indexOf) {
    Array.prototype.indexOf = function (obj, start) {
        for (var i = (start || 0), j = this.length; i < j; i++) {
            if (this[i] === obj) { return i; }
        }
        return -1;
    }
};

export function ElementClassExtensions() {

    if (!Element.prototype.hasClass) {
        Element.prototype.hasClass = function (className) {
            if (this.classList) {
                return this.classList.contains(className);
            } else {
                return (-1 < this.className.indexOf(className));
            }
        };
    };

    if (!Element.prototype.addClass) {
        Element.prototype.addClass = function (className) {
            if (this.classList) {
                this.classList.add(className);
            } else if (!this.hasClass(className)) {
                var classes = this.className.split(" ");
                classes.push(className);
                this.className = classes.join(" ");
            }
            return this;
        };
    };

    if (!Element.prototype.removeClass) {
        Element.prototype.removeClass = function (className) {
            if (this.classList) {
                this.classList.remove(className);
            } else {
                var classes = this.className.split(" ");
                classes.splice(classes.indexOf(className), 1);
                this.className = classes.join(" ");
            }
            return this;
        };
    };
};
ElementClassExtensions();

if (!HTMLCollection.prototype.forEach) {
    HTMLCollection.prototype.forEach = Array.prototype.forEach;
};

/* jQuery plugin/extensions
 */
(function ($) {
    //if (typeof $.fn.tagName === "undefined") {
    //    $.fn.tagName = function () {
    //        return this.prop("tagName");
    //    };
    //}

    //if (typeof $.fn.tagNameLowerCase === "undefined") {
    //    $.fn.tagNameLowerCase = function () {
    //        return this.prop("tagName").toLowerCase();
    //    };
    //}

    $.extend({
        replaceTag: function (element, tagName, withDataAndEvents, deepWithDataAndEvents) {
            var $element = $(element);
            var newTag = $("<" + tagName + ">")[0];
            // From [Stackoverflow: Copy all Attributes](http://stackoverflow.com/a/6753486/2096729)
            $.each(element.attributes, function () {
                newTag.setAttribute(this.name, this.value);
            });
            $element.contents().clone(withDataAndEvents, deepWithDataAndEvents).appendTo(newTag);

            let $newTag = $(newTag);
            $newTag.insertAfter($element);
            $element.remove();
            return newTag;
        }
    });

    $.fn.extend({
        replaceTag: function (tagName, withDataAndEvents, deepWithDataAndEvents) {
            // Use map to reconstruct the selector with newly created elements
            return this.map(function () {
                return $.replaceTag(this, tagName, withDataAndEvents, deepWithDataAndEvents);
            });
        },
        replaceTagIfNeed: function (tagName, withDataAndEvents, deepWithDataAndEvents) {
            // Use map to reconstruct the selector with newly created elements
            let arr = this.map(function () {
                if (tagName && tagName !== '' && (tagName.toLowerCase() != this.tagName.toLowerCase())) {
                    return $.replaceTag(this, tagName, withDataAndEvents, deepWithDataAndEvents);
                } else {
                    //console.log('>>> current tagName "' + this.tagName.toLowerCase() + '" has been skiped');
                    return this;
                }
            });
            // override prevObject
            arr.prevObject = this.prevObject;
            return arr;
        }
    });

})(jQuery);

(function($){
  $.fn.getFormData = function(){
    var data = {};
    var dataArray = $(this).serializeArray();
    for(var i=0;i<dataArray.length;i++){
      data[dataArray[i].name] = dataArray[i].value;
    }
    return data;
  }
})(jQuery);
    