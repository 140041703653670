import { hasHeaderSlider, hasHeaderSize } from 'Utils/sliders/header-slider--dependencies';
import { boxLoad } from 'SrcJS/script--boxLoad';

import 'Vendors/jquery.hcSticky';
//import 'Vendors/jquery.inview';
//import 'Vendors/jquery.ui.touch-punch';

var hasHeaderSliderDependencies = hasHeaderSlider();
var updateUI_HeaderSlider = undefined;
async function getComponent_HeaderSlider() {
    const { updateUI_HeaderSlider } = await import(/* webpackChunkName: "header-slider-module" */ 'Utils/sliders/header-slider');
    //console.log('>>> script.js >>> loaded: Utils/sliders/header-slider');
    return { updateUI_HeaderSlider };
}
var hasHeaderSizeDependencies = hasHeaderSize();
var updateUI_HeaderSize = undefined;
async function getComponent_HeaderSize() {
    const { updateUI_HeaderSize } = await import('Utils/sliders/header-slider--header-size');
    //console.log('>>> script.js >>> loaded: Utils/sliders/header-slider--header-size');
    return { updateUI_HeaderSize };
}

/***************************** UpdateUI - Dynamisk højde tilpasser sig viewport *****************************/

async function updateUI() {
    if (hasHeaderSliderDependencies) {
        if (typeof updateUI_HeaderSlider == "undefined") {
            updateUI_HeaderSlider = (await getComponent_HeaderSlider()).updateUI_HeaderSlider;
        }
        await updateUI_HeaderSlider();
    } else if (hasHeaderSizeDependencies) {
        if (typeof updateUI_HeaderSize == "undefined") {
            updateUI_HeaderSize = (await getComponent_HeaderSize()).updateUI_HeaderSize;
        }
        updateUI_HeaderSize();
    }

    /*
    // 2021-06-11 "Unused JavaScript"
    if ($(".header-slider").length) {

		var heightSearch = 60;	// Hvad der skal vises overfolden på mobil
		var heightHeader = 80;

		var windowWidth = GetBreakpointWidth();
		if (windowWidth >= 1024) {
			var heightSearch = 152;	// Hvad der skal vises overfolden på desktop
			var heightHeader = 80;
		}
		else if (windowWidth >= 768) {
			var heightSearch = 250;	// Hvad der skal vises overfolden på tablet
			var heightHeader = 80;
		}
		else if (windowWidth >= 480) {
			var heightSearch = 0;	// Hvad der skal vises overfolden på tablet
			var heightHeader = 80;
		}

		if (!erForside) heightSearch = 140;

		var height = $(window).height() - heightSearch;

		if (height < 303) height = 303;
		if (!erForside && !erRejsemaal && height > 586) height = 586;

		if (height < windowWidth * 0.33) height = windowWidth * 0.33;

		/-*
		$('.header').css('height', height);
		$('.header-slider').css('height', height - heightHeader);
		*-/

	}
    */    

	/*	Sætter en højde på kortet */
	//$('.front-map').css('height', $(window).height() - heightSearch);

	updateUI_FrontBlogMasonry();

    /* Faktabox */
	var faktaboxHeight = 0;
	if ($(window).width() > 600) {
		

		let $faktabox = $(".faktabox:not(.no-sticky), .hvorfor-hideaways, .chat-support");

		$faktabox.css("min-height", "auto");

		$faktabox.each(function () {
			temp = $(this).height();
			if ($(this).hasClass("hvorfor-hideaways")) temp += 60;
			if (temp > faktaboxHeight) {
				faktaboxHeight = temp;
			}
		});

		//$faktabox.css("min-height", faktaboxHeight);
	}

	/* Sticky */
	$(".wrapper-sticky").height(faktaboxHeight);
	if ($(window).width() > 1262) {
		if (!$(".wrapper-sticky").length) {
			$('.faktabox:not(.no-sticky)').hcSticky({
				top: 15
			});
		}
		$(".wrapper-sticky").height("auto");
		$('.faktabox').css("position", "static");
		if ($("#tabs .kolonne1").height() > $(".wrapper-sticky").height()) {
			$(".wrapper-sticky").height($("#tabs .kolonne1").height());
		} else {
			$('.faktabox').css("position", "static");
		}
	} else {
		$('.faktabox').css("position", "static");
	}


	$(".priser-afgange .kolonne1").css("margin-top", "");
	$(".priser-afgange .kolonne2").css("margin-top", "");
	if ($(window).width() > 1004) {
		let temp = $(".priser-afgange .kolonne1").height() - $(".priser-afgange .kolonne2").height() - 24;
		if (temp > 0) {
			$(".priser-afgange .kolonne2").css("margin-top", temp);
		} else {
			$(".priser-afgange .kolonne1").css("margin-top", temp * -1);
		}
	}

	/*	Gør baggrunden gul bag formfelterne */
	var focusClass = 'search__element-wrapper--active';	//Classes den tilføjes
	var $wrapper = $('.search__element-wrapper');		//Wrapper elementet

	$wrapper.children().focus(function () {
		$(this).parent().addClass(focusClass);
	}).focusout(function () {
		$(this).parent().removeClass(focusClass);
	});

	boxLoad(1);
	//UpdateSiteHeaderSticky();
    if (typeof UpdateSidebarSearchFilter != "undefined") {
        UpdateSidebarSearchFilter();
    }
};

function updateUI_FrontBlogMasonry() {
    /*	Højde / Bredde udregning til blog slideren på forsiden */
    $('.front-blog__slide').css('width', $('.front-blog__wrapper').width());

    $('.front-blog__box--large').css('height', $('.front-blog__box--large').width() * 0.8);  // Forhold mellem højde/bredde
    $('.front-blog__box--small').css('height', $('.front-blog__box--small').width() * 0.8);

    $('.front-blog').css('height', $('.front-blog__slide > div').height());
    /*	Mængden af caption i front blog der vises i kassen */
};


export { updateUI }