window.lazySizesConfig = window.lazySizesConfig || {};
//in case you want to use custom media query aliases in your markup, instead of full media queries
window.lazySizesConfig.customMedia = {
    '--bp0': 'only screen and (min-width: 0px)',
    '--bp1': 'only screen and (min-width: 480px)',
    '--bp2': 'only screen and (min-width: 768px)',
    '--bp3': 'only screen and (min-width: 1024px)',
    '--bp4': 'only screen and (min-width: 1280px)',
};

window.lazySizesConfig.init = false;

window.lazySizesConfig.loadMode = 1;
window.lazySizesConfig.loadHidden = false;
window.lazySizesConfig.expand = 10;