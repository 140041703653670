
export function hasHeaderSlider() {
    let result = (document.querySelector('.header-slider') != null);
    //console.log('>>> hasHeaderSlider: ' + result);
    return result;
}

export function hasHeaderSize() {
    let result = (document.querySelector('body.body-pagetype-45') != null)
        || (document.querySelector('body.body-pagetype-50') != null)
        || (document.querySelector('body[class*="header-size--"]') != null)
        || (document.querySelector('body.header-size--none') != null)
        || (document.querySelector('body.header-size--big') != null)
        || (document.querySelector('body.header-size--medium') != null)
        ;
    //console.log('>>> hasHeaderSize: ' + result);
    return result;
}
