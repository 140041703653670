//import 'jquery-ui/ui/widgets/autocomplete'; ///!!!! bundling
import 'Modules/navbar';
import 'Vendors/slimmage/slimmage.js';
//import  'Utils/sliders/header-slider';
import { hasHeaderSlider } from 'Utils/sliders/header-slider--dependencies';


async function getComponent_HeaderSlider() {
    const { InitScript_HeaderSlider, updateUI_HeaderSlider, InitGlobalScripts } = await import(/* webpackChunkName: "header-slider-module" */ 'Utils/sliders/header-slider');
    console.log('>>> header.js >>> loaded: Utils/sliders/header-slider');
    //InitGlobalScripts();
    return { InitScript_HeaderSlider, updateUI_HeaderSlider, InitGlobalScripts };
}

if (hasHeaderSlider()) {
    console.log('>>> header.js >>> hasHeaderSlider == true');
    getComponent_HeaderSlider().then((component) => {
        console.log('>>> header.js >>> done: getComponent_HeaderSlider');
    }).catch((error) => 'An error occurred while loading the component');
}